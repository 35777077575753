<div class="pricing">
    <div class="row d-flex justify-content-center py-5">
        <div class="col-12 col-md-6 mt-5 mt-md-0 pe-md-5">
            <img class="d-flex ms-auto " style="width: 100%; border-radius: 16px;" src="./assets/images/mockup5.png">
        </div>
        <div class="col-12 col-md-5 mt-0 mt-md-3 mt-lg-4 mt-xl-5 pt-0 pt-lg-4 pt-xl-5 px-3 px-md-4">
            <!-- <h1>Plan Total</h1>
            <h2>$1,499.00<span> / mes</span></h2> -->
            <h1>Prueba gratuita</h1>
            <h5>Inicia tu periodo de prueba sin introducir una forma de pago.</h5>
            <a routerLink="/usuario/registro" role="button" class="mt-5 btn btn-outline-primary">Comenzar</a>
        </div>
        
    </div>

    <div class="row d-flex justify-content-center py-5">
        <div class="col-11 col-md-10 col-lg-8">
            <div class="px-4 py-3">
                <h3 class="mb-5 text-center">Lo que está incluido</h3>
                <div class="row">
                    <div class="col-12 col-md-6 mb-4">
                        <h5>Plataformas</h5>
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>App móvil para iPhone</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>App móvil para Android</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Administrador web desde cualquier navegador</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <h5>Usuarios</h5>
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Control de acceso a usuarios</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Acceso de administradores</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Acceso de trabajadores</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <h5>Almacenamiento</h5>
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Número ilimitado de administradores</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Número ilimitado de clientes</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Número ilimitado de trabajadores</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Almacena información de todos los autos</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Número de autos por cliente ilimitado</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <h5>Extra</h5>
                        <table class="table table-borderless">
                            <tbody>
                                
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Almacena información de tu compañía</td>
                                </tr>
                                <!-- <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Almacena los precios de los servicios que ofrecen</td>
                                </tr> -->
                                <!-- <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Resumen de ventas de la compañía</td>
                                </tr> -->
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Pago automatizado</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Paperless</td>
                                </tr>
                                <tr>
                                    <th scope="row"><img style="width: 18px;" src="./assets/images/check.png"></th>
                                    <td>Obtén todas las actualizaciones</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="text-center py-3">
                    <button class="btn btn-primary">Comenzar</button>
                </div>
            </div>
        </div>
    </div>
</div>