import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import localePy from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './shared/home/home.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { ContactComponent } from './shared/contact/contact.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app.routing.module';
import { environment } from '../environments/environment';
import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';
import { NgxPayPalModule } from 'ngx-paypal';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { PricingComponent } from './shared/pricing/pricing.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
registerLocaleData(localePy, 'es-MX');

export const firebaseConfig = {
  apiKey: 'AIzaSyDLsC1IeHI1Xrlx3B36MyGzz8T8JxGqmHE',
  authDomain: 'pinganar-d7512.firebaseapp.com',
  databaseURL: 'https://pinganar-d7512.firebaseio.com',
  projectId: 'pinganar-d7512',
  storageBucket: 'pinganar-d7512.appspot.com',
  messagingSenderId: '389304775402',
  appId: '1:389304775402:web:ccf559a132f611f5c31d93',
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    NavbarComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    PricingComponent,
    SidebarComponent,
  ],
  imports: [
    NgxPayPalModule,
    BrowserModule,
    AngularFireAuthGuardModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
