<div class="container-fluid p-0" *ngIf="!currentUser">
  <navbar></navbar>
  <div class="app">
    <router-outlet></router-outlet>
  </div>
  <footer></footer>
</div>
 
<div class="row app" *ngIf="currentUser">
  <div class="col-xs-7 col-12 col-md-3 col-lg-2" style="padding-right: 0px;padding-left: 0px;">
    <sidebar></sidebar>
  </div>
  <div class="col-12 col-md-9 col-lg-10">
    <router-outlet></router-outlet>
  </div>
</div>