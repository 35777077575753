import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import 'firebase/firestore';
import { concatMap, toArray, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(public firestore: AngularFirestore) {}

  async createAdmin(admin) {
    const docRef = await this.firestore.collection('admins').add(admin);
    return docRef.id;
  }
  async updateAdmin(adminId, data) {
    console.log(adminId, data);
    try {
      await this.firestore.collection('admins').doc(adminId).update(data);
      return true;
    } catch (error) {
      return error;
    }
  }

  async searchByEmail(email) {
    console.log('getting email', email);
    return this.firestore
      .collection('admins', (ref) => ref.where('email', '==', email))
      .get()
      .pipe(
        take(1),
        concatMap((x) => x.docs),
        map((admin) => {
          console.log(admin.id, admin.data());
          const data: any = admin.data();
          const uid = admin.id;

          return { uid, ...data };
        }),
        toArray()
      )
      .toPromise();
  }

  async searchByCompany(companyId) {
    console.log('getting email', companyId);
    return this.firestore
      .collection('admins', (ref) => ref.where('companyId', '==', companyId))
      .get()
      .pipe(
        take(1),
        concatMap((x) => x.docs),
        map((admin) => {
          console.log(admin.id, admin.data());
          const data: any = admin.data();
          const uid = admin.id;

          return { uid, ...data };
        }),
        toArray()
      )
      .toPromise();
  }
  getAdmin(adminId) {
    return this.firestore.collection(`admins/`).doc(adminId);
  }
}
