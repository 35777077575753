<div class="contact">
    <div class="row d-flex justify-content-center py-4">
        <div class="col-10 col-md-5 col-lg-4 my-4">
            <div class="form px-4 px-md-5 py-3">
            <h2 class="mt-4 mb-5 text-center">Déjanos un mensaje</h2>
            <form [formGroup]="contactForm" method="post" (ngSubmit)="onSubmit()">
                <div class="form-group mb-4">
                    <input type="text" class="form-control" name="Fullname" placeholder="Nombre completo" [formControl]="name" required>
                </div>
                <div
                *ngIf="
                name.invalid && (name.dirty || name.touched)
                "
                class="alert alert-danger"
              >
                <div *ngIf="name.errors.required">Ingresa tu nombre.</div>
              </div>
                <div class="form-group mb-4">
                    <input type="email" class="form-control" name="Email" aria-describedby="emailHelp" placeholder="Correo electrónico" [formControl]="email">
                </div>
                <div
                *ngIf="
                email.invalid && (email.dirty || email.touched)
                "
                class="alert alert-danger"
              >
                <div *ngIf="email.errors.required">Ingresa un correo electrónico para contactarte.</div>
              </div>
                <div class="form-group mb-4">
                    <input type="phone" class="form-control" name="Phone" placeholder="Teléfono" [formControl]="phone" required>
                </div>
                <div
                *ngIf="
                phone.invalid && (phone.dirty || phone.touched)
                "
                class="alert alert-danger"
              >
                <div *ngIf="phone.errors.required">Ingresa un número para contactarte.</div>
              </div>
                <div class="form-group mb-4">
                    <textarea class="form-control" placeholder="Mensaje" name="Comment" [formControl]="message"></textarea>
                </div>
                <div
                *ngIf="
                message.invalid && (message.dirty || message.touched)
                "
                class="alert alert-danger"
              >
                <div *ngIf="message.errors.required">Escribe un mensaje.</div>
              </div>
                <div class="mt-4">
                    <button  [disabled]="!contactForm.valid" type="submit" class="submit btn btn-primary btn-block">Enviar</button>
                </div>
                <div [ngClass]="!submitted? 'hidden' : 'visible'" class="center-text mt-3">
                    <span>{{responseMessage}}</span>
                </div>
            </form>
            <!-- <h5 class="mt-4 text-center">Contáctanos</h5>
            <div class="row d-flex justify-content-center mt-3">
                <div class="col-12 col-md-7 col-lg-8 text-center">
                    <p><a style="color: inherit; font-family: OpenSansRegular;" href="mailto:"><img src="./assets/images/icons-on-mail.png">  hola@sanperro.mx</a></p>
                </div>
            </div>
            <div class="row d-flex justify-content-center pb-3">
                <a class="mx-2" target="_blank" href="https://www.facebook.com/"><img src="./assets/images/icon-social-facebook.png"></a>
                <a class="mx-2" target="_blank" href="https://www.instagram.com/"><img src="./assets/images/icon-social-instagram.png"></a>
            </div> -->
            </div>
        </div>
    </div>
</div>