import { Injectable, Output, EventEmitter,NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AdminService } from '../services/data/admin.service';
import { Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { signInErrorsFB } from './constants/firebase';
import { switchMap, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser$: BehaviorSubject<any> = new BehaviorSubject(null);
  public currentUser;
  public destroy$ = new Subject<boolean>();
  constructor(
    public auth: AngularFireAuth,
    private adminS: AdminService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // con corchetes sacas el primer elemento del arreglo
        const [user] = await this.adminS.searchByEmail(authUser.email);

        if (user) {
          console.log(user);
          this.currentUser$.next(user);
          this.currentUser = user;
          this.listenToUserChanges();
        } else {
          // await this.signOut();

        }
      } else {
      }
    });
  }

  public async updateUserData(){

      // con corchetes sacas el primer elemento del arreglo
      const [user] = await this.adminS.searchByEmail(this.currentUser.email);

      if (user) {
        console.log(user);
        this.currentUser$.next(user);
        this.currentUser = user;
        this.listenToUserChanges();
      } else {
        // await this.signOut();

      }
  
  }
  public async signUp(email, password) {
    const [user] = await this.adminS.searchByEmail(email);
    if (user) {
      console.log(user);
      this.currentUser$.next(user);
      this.currentUser = user;
      this.listenToUserChanges();
    } else {
      // await this.signOut();
      
    }
    try {
    const user = await this.auth.createUserWithEmailAndPassword(email, password);
    return user.user.uid
    } catch (err) {
      console.log(err)
      throw new Error(signInErrorsFB.signInErrors(err.code));
    }
  }

  public async signIn(email, password) {
    console.log(email, password);
    const user = await this.adminS.searchByEmail(email);
    console.log(user);
    if (user.length > 0) {
      try {
        await this.auth.signInWithEmailAndPassword(email, password);
        this.router.navigate(['/admin']);
        console.log('user logged in');
      } catch (err) {
        console.log(err);
        throw new Error(signInErrorsFB.signInErrors(err.code));      }
    } else {
      console.log('User not exists');
    }
    
  }

  private listenToUserChanges() {
    if (this.currentUser) {
      this.adminS
        .getAdmin(this.currentUser.id)
        .snapshotChanges()
        .pipe(takeUntil(this.destroy$))
        .subscribe((snapshot) => {
          if (snapshot.type === 'modified') {
            this.currentUser = {
              id: this.currentUser.id,
              data: snapshot.payload.data(),
            };

            this.currentUser$.next(this.currentUser);
          }
        });
    }
  }

  // public async getDetails() {
  //   await this.auth.onAuthStateChanged(function (user) {
  //     if (user) {

  //       const email = user.email;

  //       return email;
  //     } else {
  //       return null;
  //     }
  //   });
  //   return;
  // }
  public async adminProperties(email) {
    const admin = await this.adminS.searchByEmail(email);
    console.log(admin[0], 'hay admin');
    localStorage.setItem('rcsystem-admin', JSON.stringify(admin[0]));
  }
  // this.clients = await Promise.all(
  //   clients.map(async (client) => ({
  //     ...client,
  //     cars: await this.getCars(client.id),
  //   }))
  // );

  public async signOut() {
    localStorage.removeItem('rcsystem-admin');
    await this.auth.signOut();
    this.ngZone.run(() => this.router.navigate(['/'])) 
  }

  public async ForgotPassword(email) {
    await this.auth.sendPasswordResetEmail(email);
    return true;
  }

  // get isLoggedIn(): boolean {
  //   const user = localStorage.getItem('san-perro-uid');
  //   return user !== null ? true : false;
  // }
}
