<!-- <div *ngIf="!currentUser">
  <ul ngbNav [activeId]="route.fragment | async" class="nav-tabs">
    <li [ngbNavItem]="link.fragment" *ngFor="let link of links">
      <a ngbNavLink routerLink="{{link.fragment}}" >{{ link.title }}</a>
    </li>
  </ul>
</div> -->

<!-- <div class=""  *ngIf="!currentUser"> -->
  <div class=""  >
  <nav class="navbar navbar-expand-lg navbar-light top-fixed">
    <div class="container-fluid">
      <a class="navbar-brand"  routerLink="/">
        <img style="width: 80px;" src="./assets/images/RCSystems-short-logo.png">
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
      <ul ngbNav [activeId]="route.fragment | async" class="navbar-nav ">
        <li class="nav-item" [ngbNavItem]="link.fragment" *ngFor="let link of links">
          <a ngbNavLink routerLink="{{link.fragment}}" >{{ link.title }}</a>
        </li>
      </ul>
        <!-- <div *ngIf="!currentUser">
          <a
            role="button"
            routerLink="/usuario/entrar"
            class="btn btn-outline-primary mr-md-3"
            (click)="isCollapsed = !isCollapsed"
            >Iniciar</a
          >
          <a
            role="button"
            routerLink="/usuario/registro"
            class="btn btn-primary my-3 my-md-3"
            (click)="isCollapsed = !isCollapsed"
            >Únete</a
          >
        </div> -->
      </div>
    </div>
  </nav>
</div>
