<div class="footer">
    <div class="row d-flex justify-content-center py-5 px-5">
        <div class="col-11 col-md-3">
            <img class="d-flex float-start mb-3" width="80%" src="./assets/images/RCSystems-full.png">
            <h6>Mejoramos tu forma de trabajar.</h6>
        </div>
        <div class="col-6 col-md-4 col-lg-3 offset-md-1 mt-5 mt-md-3">
            <h5 class="mb-2">Menú</h5>
            <p><a routerLink="/precios">Qué incluye</a></p>
            <p><a routerLink="/admin">Admin</a></p>
            <p><a routerLink="/contacto">Contacto</a></p>
            <!-- <p><a routerLink="/">Ayuda</a></p> -->
        </div>
        <div class="col-6 col-md-4 col-lg-3 mt-5 mt-md-3">
            <h5 class="mb-2">Descarga</h5>
            <p><a target="_blank" href="https://play.google.com/store/apps/details?id=mx.com.rcsystems&hl=es_MX">Google Play</a></p>
            <p><a target="_blank" href="">App Store</a></p>
        </div>

    </div>
    <div class="col-12 text-center pb-3">
        <small>Copyright © 2022 RC Systems. Diseño y tecnología por <a target="_blank" href="http://citrus-lab.com/">Citrus lab</a>.</small>
    </div>
</div>