import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {
  AngularFireAuthGuard,
  hasCustomClaim,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
} from '@angular/fire/auth-guard';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import(`./admin/admin.module`).then((module) => module.AdminModule),
  },
  {
    path: 'ordenes',
    canActivate: [AngularFireAuthGuard],
    loadChildren: () =>
      import(`./orders/orders.module`).then((module) => module.OrdersModule),
  },
  {
    path: 'usuario',
    loadChildren: () =>
      import(`./login/login.module`).then((module) => module.LoginModule),
  },
  {
    path: '',

    loadChildren: () =>
      import(`./shared/shared-routing.module`).then(
        (module) => module.SharedRoutingModule
      ),
  },
  { path: '**', redirectTo: '' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
