import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/login/auth.service';
@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isCollapsed = true;
  constructor(

    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

async  logout(){
  console.log('salir')
  
    await this.authService.signOut()
}

}
