import { Component, OnInit,   NgZone ,  ChangeDetectorRef} from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { AuthService } from './login/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'RC Systems';
  currentUser;

  constructor(
    public route: ActivatedRoute,
    private authS: AuthService,
    private changeDet: ChangeDetectorRef,
    private changeDetection: ChangeDetectorRef,
    private router: Router,

    private ngZone: NgZone

  ) {} 

   ngOnInit() {
    this.authS.currentUser$.subscribe((user) => {
      this.currentUser = user ? true : false;
      this.changeDetection.detectChanges();
      if (!this.currentUser) {
        this.ngZone.run(() => this.router.navigate(['/']));
      }
    });
  }
}
