export const signInErrors = (code) => {
  switch (code) {
    case 'auth/user-not-found':
      return `Usuario no encontrado.`;
    case 'auth/wrong-password':
      return `Contraseña inválida.`;
    case 'auth/invalid-email':
      return `Email inválido.`;
    default:
      return `Algo ha salido mal.`;
  }
};
