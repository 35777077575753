import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

 contactForm = this.formBuilder.group({
    name: [
      '',
      Validators.required,
    ],
    email: [
      '',
      Validators.required,
    ],
    phone: [
      '',
      Validators.required,
    ],
    message: [
      '',
      Validators.required,
    ],
  
  
  })


  submitted = false; // show and hide the success message
  isLoading = false; // disable the submit button if we're loading
  responseMessage: string; // the response message to show to the user
  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
  
  }
  ngOnInit(): void {
    return;
  }
  onSubmit() {
    console.log(this.contactForm)
    if (this.contactForm.status == 'VALID') {
     
      const formData: FormData = new FormData();
      formData.append('name', this.contactForm.get('name').value);
      formData.append('email', this.contactForm.get('email').value);
      formData.append('message', this.contactForm.get('message').value);
      formData.append('phone', this.contactForm.get('phone').value);
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits
      this.http
        .post(
          'https://script.google.com/macros/s/AKfycbzEwXM6E1mk7Ilo67wTrM995Ou0UE1ZrBB7eO6NcaYXyBS3rua5chm1rcl2VJDMlpsu/exec',
          formData
        )
        .subscribe(
          (response) => {
            // choose the response message
            if (response['result'] == 'success') {
              this.responseMessage =
                '¡Gracias por tu interés! Pronto estaremos en contacto contigo.';
            } else {
              this.responseMessage =
                'Algo salió mal... Vuelve a cargar la página e intenta de nuevo.';
            }
            this.contactForm.enable(); // re enable the form after a success
            this.submitted = true; // show the response message
            this.isLoading = false; // re enable the submit button
          },
          (error) => {
            this.responseMessage =
              'Ocurrió un error... Vuelve a cargar la página e intenta de nuevo.';
            this.contactForm.enable(); // re enable the form after a success
            this.submitted = true; // show the response message
            this.isLoading = false; // re enable the submit button
          }
        );
    }
  }
  get name() {
    return this.contactForm.get('name');
  }

  get email() {
    return this.contactForm.get('email');
  }
  get phone() {
    return this.contactForm.get('phone');
  }
  get message() {
    return this.contactForm.get('message');
  }
}
