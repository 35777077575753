<!-- <div class="landing" style="text-align:center">
    <div class="welcome">
      <h1 class="centered text-white">Cambia tu forma de trabajar</h1>
    </div>
</div> -->
<div class="py-5">
    <div class="row d-flex justify-content-center ">
        <div class="col-12 col-md-5 mt-0 mt-md-3 mt-lg-4 mt-xl-5 pt-0 pt-lg-4 pt-xl-5 px-3 px-md-4 text-center">
            <h2 class="mb-4">Cambia tu forma de trabajar</h2>
            <h5 style="color: #2c2c2c;">Con RCSystems tendrás el control total de tu taller desde tu smartphone.</h5>
            <button (click)="redirectRegistro()" class="mt-4 btn btn-outline-primary">Quiero iniciar</button>
        </div>
        <div class="col-12 col-md-6 mt-5 mt-md-0">
            <img style="width: 100%; border-radius: 16px;" src="./assets/images/iPhoneXMOCKUP.png">
        </div>
    </div>
</div>
  
<div class="">
    <div class="intro row d-flex justify-content-center py-5 my-md-5">
        <div class="col-11 col-md-3 mr-0 me-md-3 my-4 my-md-0">
            <h5 class="mb-4">Digitaliza la creación y seguimiento de tus órdenes</h5>
            <p>RC Systems te ayudará a controlar el trabajo en tu taller; guarda la información de tus clientes, sus autos y el historial de órdenes.</p>
        </div>
        <div class="col-11 col-md-3 mr-0 me-md-3 my-4 my-md-0">
            <h5 class="mb-4">Optimiza el trabajo y la comunicación con tus clientes</h5>
            <p>Cada orden tendrá un administrador responsable de asignarla a un trabajador y de comunicar al cliente el estado.</p>
        </div>
        <div class="col-11 col-md-3 mr-0 me-md-3 my-4 my-md-0">
            <h5 class="mb-4">Creado por expertos de la industria automotriz</h5>
            <p>La plataforma de RC Systems responde a las necesidades de la industria de digitalizar y mejorar la forma de trabajar.</p>
        </div>
    </div>
    <div class="row d-flex justify-content-center py-md-5">
        <div class="col-12 col-md-6 mt-5 mt-md-0">
            <img style="width: 100%; border-radius: 16px;" src="./assets/images/mockup11.png">
        </div>
        <div class="col-12 col-md-5 mt-4 mt-md-3 mt-lg-4 mt-xl-5 pt-0 pt-lg-4 pt-xl-5 px-3 px-md-4">
            <h2 class="mb-4">Cómo funciona</h2>
            <!-- <h6>1. Inicia una suscripción mensual</h6> -->
            <h6>1. Regístrate</h6>
            <h6>2. Ingresa la información de tu compañía</h6>
            <h6>3. Invita a tus trabajadores</h6>
            <h6>4. Controla las órdenes desde la app</h6>
            <button class="mt-4 btn btn-primary" (click)="redirectRegistro()">Iniciar registro</button>
        </div>
        
    </div>
    <div class="queincluye row d-flex justify-content-center text-center py-5 my-5">
        <!-- <h2 class="mb-5">¿Qué obtengo con mi suscripción?</h2> -->
        <h2 class="mb-3 mb-md-5">¿Qué obtengo al registrarme?</h2>
        <div class="col-11 col-md-3 my-3 my-md-0">
            <h5>Acceso al administrador web</h5>
        </div>
        <div class="col-11 col-md-3 my-3 my-md-0">
            <h5>Aplicación móvil para dispositivos Android y iOS</h5>
        </div>
        <div class="col-11 col-md-3 my-3 my-md-0">
            <h5>Creación de clientes y órdenes ilimitadas</h5>
        </div>
        <div class="col-11">
            <button routerLink="/precios" class="mt-4 btn btn-outline-light">Ver todo lo que incluye</button>
        </div>
    </div>
    <div class="row d-flex justify-content-center py-md-5">
        <div class="col-12 col-md-6 mt-5 mt-md-0 pe-md-5">
            <img class="d-flex mx-auto mx-md-0 ms-md-auto " style="width: 60%; border-radius: 16px;" src="./assets/images/Mockup9.png">
        </div>
        <div class="col-12 col-md-5 mt-0 mt-md-3 mt-lg-4 mt-xl-5 pt-4 pt-lg-4 pt-xl-5 px-3 px-md-4">
            <h2 class="mb-4">Control total de tus órdenes</h2>
            <h6>• Crea una orden</h6>
            <h6>• Cotiza en automático</h6>
            <h6>• Actualiza el estado de cada orden</h6>
            <h6>• Guarda la información de tus clientes</h6>
            <h6>• Asigna órdenes a tus trabajadores</h6>
            
            <a class="mt-4 mb-5 btn btn-outline-primary" routerLink="/usuario/registro">Quiero cambiar mi forma de trabajar</a>
        </div>
        
    </div>

    <!-- <div class="row py-5 d-flex justify-content-center">
        <div class="col-10 col-md-3">
            <div class="info py-3 px-3 py-md-4 px-md-4">
                <h5 class="mb-3">Órdenes</h5>
                <p><span style="font-size: 22px;">10</span> activas</p>

            </div>
        </div>
        <div class="col-10 col-md-3">
            <div class="info py-3 px-3 py-md-4 px-md-4">
                <h5 class="mb-3">Usuarios</h5>
                <p><span style="font-size: 22px;">3</span> empleados</p>

            </div>
        </div>
        <div class="col-10 col-md-3">
            <div class="info py-3 px-3 py-md-4 px-md-4">
                <h5 class="mb-3">Clientes</h5>
                <p><span style="font-size: 22px;">50</span> clientes</p>

            </div>
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-11 col-md-8">
            
        </div>
    </div> -->
</div>