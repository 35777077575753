// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDLsC1IeHI1Xrlx3B36MyGzz8T8JxGqmHE',
    authDomain: 'pinganar-d7512.firebaseapp.com',
    databaseURL: 'https://pinganar-d7512.firebaseio.com',
    projectId: 'pinganar-d7512',
    storageBucket: 'pinganar-d7512.appspot.com',
    messagingSenderId: '389304775402',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
