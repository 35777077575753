import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../login/auth.service';
@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  admin;
  currentUser;
  links = [
    { title: 'Inicio', fragment: '/' },
    // { title: 'Precios', fragment: '/precios' },
    { title: 'Qué incluye', fragment: '/precios' },    
    { title: 'Contacto', fragment: '/contacto' },
    { title: 'Entrar', fragment: '/usuario/login' },
    // { title: 'Suscripción', fragment: '/usuario/registro' },
    { title: 'Registro', fragment: '/usuario/registro' },
  ];
  constructor(
    public route: ActivatedRoute,
    private authS: AuthService,
    private changeDet: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.authS.currentUser$.subscribe((user) => {
      this.currentUser = user;
      if (this.currentUser != null) {
        console.log(this.currentUser);
      }
    });
  }
}
