<div class="nav-md">
    <nav id="sidebarMenu" class="d-md-block sidebar">
      <div class="">
        <img  class="my-3 mx-md-3 mx-lg-4 d-flex mx-auto" style="width: 100px;" src="../assets/images/RCSystems-short-white.png">
      </div>
      <div class="sidebar-sticky mt-5 pt-4 mx-md-4">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/ordenes">
              Órdenes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/usuarios">
              Usuarios
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/clientes">
              Clientes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/configuracion">
              Cuenta
            </a>
          </li>
          <br>
          <li class="nav-item">
            <a class="nav-link" (click)="logout()">
              Salir
            </a>
          </li> 
        </ul>
      </div>
    </nav>
 
  </div>
  <div class="nav-sm">
    <nav class="navbar navbar-brand-center navbar-expand-lg navbar-dark" style="background: linear-gradient(90deg, rgba(80,89,231,1) 0%, rgba(168,125,244,1) 100%);">
      <div class="container-fluid">
        <a class="navbar-brand " routerLink="/admin"><img style="width: 70px;" src="./assets/images/RCSystems-short-white.png"></a>
        <button class="navbar-toggler" (click)="isCollapsed = !isCollapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" [attr.aria-expanded]="!isCollapsed">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="navbarSupportedContent">
          <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" (click)="isCollapsed = !isCollapsed" routerLink="/admin/ordenes">Órdenes</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="isCollapsed = !isCollapsed" routerLink="/admin/usuarios">Usuarios</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="isCollapsed = !isCollapsed" routerLink="/admin/clientes">Clientes</a>
            </li>
            <!-- <li class="nav-item">
              <a class="nav-link" (click)="signOut()" (click)="isCollapsed = !isCollapsed">Cerrar sesión</a>
            </li> -->
          </ul>
        </div>
     
      </div>
    </nav>
  </div>